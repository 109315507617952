/* eslint-disable max-len */
import React from 'react';
import {
  Box,
  Typography,
  Container,
  List as MuiList,
  ListItem as MuiListItem,
  Link,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { styled } from '@mui/material/styles';
// import NotFound from '../NotFound';

const List = styled(MuiList)(() => ({
  // display: 'list-item',
  listStyleType: 'disc',
}));

const ListItem = styled(MuiListItem)(() => ({
  display: 'list-item',
  listStyleType: 'disc',
  marginLeft: '24px',
  pl: 1,
}));

export default function TermsAndConditions() {
  return (
    <Box sx={{
      height: 'auto',
      '@media (min-width: 600px)': {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
    }}
    >
      <Container sx={{
        pt: 10,
        paddingBottom: 10,
        pl: { sm: 2.5, md: 3, lg: 7 },
        pr: { sm: 2.5, md: 3, lg: 12 },
      }}
      >
        <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>Terms and Conditions</Typography>
        <Typography sx={{ my: 0.5 }}>Last updated: November 10, 2022</Typography>
        <Typography sx={{ my: 0.5 }}>Please read these terms and conditions carefully before using Our Service.</Typography>
        <Typography variant="h6" sx={{ my: 2 }}>Interpretation and Definitions</Typography>
        <Typography variant="h3" sx={{ my: 2 }}>Interpretation</Typography>
        <Typography sx={{ my: 0.5 }}>
          The words of which the initial letter is capitalized have meanings defined under the following conditions.
          The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
        </Typography>
        <Typography variant="h3" sx={{ my: 2 }}>Definitions</Typography>
        <Typography sx={{ my: 0.5 }}>For the purposes of these Terms and Conditions:</Typography>

        <List>
          <ListItem>
            <Typography sx={{ my: 0.5 }}>
              <strong>Application</strong>
              {' '}
              means the software program provided by the Company downloaded by You on any electronic device, named QuantaSafe
            </Typography>
          </ListItem>

          <ListItem>
            <Typography sx={{ my: 0.5 }}>
              <strong>Application Store</strong>
              {' '}
              means the digital distribution service operated and developed by Apple Inc.
              (Apple App Store) or Google Inc. (Google Play Store) in which the Application has been downloaded.
            </Typography>
          </ListItem>

          <ListItem>
            <Typography sx={{ my: 0.5 }}>
              <strong>Affiliate</strong>
              {' '}
              means an entity that controls, is controlled by or is under common control with a party,
              where &quot;control&quot; means ownership of 50% or more of the shares,
              equity interest or other securities entitled to vote for election of directors or other managing authority.
            </Typography>
          </ListItem>

          <ListItem>
            <Typography sx={{ my: 0.5 }}>
              <strong>Country</strong>
              {' '}
              refers to: Minnesota, United States
            </Typography>
          </ListItem>

          <ListItem>
            <Typography sx={{ my: 0.5 }}>
              <strong>Company</strong>
              {' '}
              (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to VoiceIt Technologies, Inc,
              222 S 9th Street, Suite 1600, Minneapolis, MN 55402.
            </Typography>
            <Typography sx={{ my: 0.5 }}>For the purpose of the GDPR, the Company is the Data Controller.</Typography>
          </ListItem>

          <ListItem>
            <Typography sx={{ my: 0.5 }}>
              <strong>Device</strong>
              {' '}
              means any device that can access the Service such as a computer, a cellphone or a digital tablet.
            </Typography>
          </ListItem>

          <ListItem>
            <Typography sx={{ my: 0.5 }}>
              <strong>Service</strong>
              {' '}
              refers to the Application or the Website or both.
            </Typography>
          </ListItem>

          <ListItem>
            <Typography sx={{ my: 0.5 }}>
              <strong>Terms and Conditions </strong>
              {' '}
              (also referred as &quot;Terms&quot;) mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.
            </Typography>
          </ListItem>

          <ListItem>
            <Typography sx={{ my: 0.5 }}>
              <strong>Third-party Social Media Services</strong>
              {' '}
              means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.
            </Typography>
          </ListItem>

          <ListItem>
            <Typography sx={{ my: 0.5 }}>
              <strong>Website</strong>
              {' '}
              refers to VoiceIt Technologies, Inc, accessible from
              {' '}
              <Link href="https://voiceit.io" rel="external nofollow noopener noreferrer" target="_blank">https://voiceit.io</Link>
            </Typography>
          </ListItem>

          <ListItem>
            <Typography sx={{ my: 0.5 }}>
              <strong>You</strong>
              {' '}
              means the individual accessing or using the Service, or the company,
              or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
            </Typography>
            <Typography sx={{ my: 0.5 }}>
              Under GDPR (General Data Protection Regulation),
              You can be referred to as the Data Subject or as the User as you are the individual using the Service.

            </Typography>
          </ListItem>
        </List>

        <Typography variant="h6" sx={{ my: 2 }}>Acknowledgment</Typography>
        <Typography sx={{ my: 0.5 }}>
          These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company.
          These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.

        </Typography>
        <Typography sx={{ my: 0.5 }}>
          Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions.
          These Terms and Conditions apply to all visitors, users and others who access or use the Service.

        </Typography>
        <Typography sx={{ my: 0.5 }}>
          By accessing or using the Service You agree to be bound by these Terms and Conditions.
          If You disagree with any part of these Terms and Conditions then You may not access the Service.

        </Typography>
        <Typography sx={{ my: 0.5 }}>
          You represent that you are over the age of 18.
          The Company does not permit those under 18 to use the Service.

        </Typography>
        <Typography sx={{ my: 0.5 }}>
          Your access to and use of the Service is also conditioned on
          Your acceptance of and compliance with the Privacy Policy of the Company.
          Our Privacy Policy describes Our policies and procedures on the collection,
          use and disclosure of Your personal information when You use the Application or
          the Website and tells You about Your privacy rights and how the law protects You.
          Please read Our Privacy Policy carefully before using Our Service.

        </Typography>

        <Typography variant="h6" sx={{ my: 2 }}>Intellectual Property</Typography>
        <Typography sx={{ my: 0.5 }}>
          The Service and its original content (excluding Content provided by You or other users),
          features and functionality are and will remain the exclusive property of the Company and its licensors.

        </Typography>
        <Typography sx={{ my: 0.5 }}>
          The Service is protected by copyright, trademark,
          and other laws of both the Country and foreign countries.

        </Typography>
        <Typography sx={{ my: 0.5 }}>
          Our trademarks and trade dress may not be used in connection with any
          product or service without the prior written consent of the Company.

        </Typography>

        <Typography variant="h6" sx={{ my: 2 }}>Links to Other Websites</Typography>
        <Typography sx={{ my: 0.5 }}>
          Our Service may contain links to third-party web sites or services
          that are not owned or controlled by the Company.

        </Typography>
        <Typography sx={{ my: 0.5 }}>
          The Company has no control over, and assumes no responsibility for,
          the content, privacy policies, or practices of any third party web sites or services.
          You further acknowledge and agree that the Company shall not be responsible or liable,
          directly or indirectly, for any damage or loss caused or alleged to be caused by or
          in connection with the use of or reliance on any such content, goods or services available
          on or through any such web sites or services.

        </Typography>
        <Typography sx={{ my: 0.5 }}>
          We strongly advise You to read the terms and conditions
          and privacy policies of any third-party web sites or services that You visit.

        </Typography>

        <Typography variant="h6" sx={{ my: 2 }}>Use of VoiceIt Technologies, Inc. Services</Typography>
        <Typography sx={{ my: 0.5 }}>
          You will be solely responsible for all use (whether or not authorized) of the VoiceIt Technologies, Inc Services and Documentation under its account,
          including for the quality and integrity of Customer Data and each Customer Application.
          You will ensure that you have a written agreement with each End User (&quot;End User Agreement&quot;) that protects VoiceIt Technologies, Inc&apos;s rights to
          the same extent as the terms of this Agreement. The End User Agreement must include terms concerning restrictions on use,
          protection of proprietary rights, disclaimer of warranties and limitations of liability. VoiceIt Technologies, Inc agrees that,
          subject to the foregoing requirements, the End User Agreement need not specifically refer to VoiceIt Technologies, Inc.
          You will promptly notify VoiceIt Technologies, Inc if it becomes aware of any breach of the terms of the End User Agreement that may affect VoiceIt Technologies, Inc.
          You will take all reasonable precautions to prevent unauthorized access to or use of the VoiceIt Technologies, Inc Services and notify VoiceIt Technologies, Inc promptly of
          any such unauthorized access or use.  You agree that in your use of the website:
          {' '}

        </Typography>
        <List>
          <ListItem> You may not intentionally post, create, upload or transmit any software or other material which contains a virus or other harmful code or device; </ListItem>
          <ListItem> You will not impersonate any other person or use a false identity (the name of some other living or deceased person); </ListItem>
          <ListItem> You are responsible for all postings or other activity performed using your username and password; </ListItem>
          <ListItem> You will not access or attempt to access the account of any other person, or any other area or section of this website that is restricted and to which you have not explicitly been granted access; </ListItem>
          <ListItem> You will not take any action designed to interfere with the operation of this website; </ListItem>
          <ListItem>
            {' '}
            If you are discussing voice biometrics on your website or application, you need to give credit and display our &quot;Powered by VoiceIt Technologies, Inc&quot; badge as shown on the
            {' '}
            <Link component={RouterLink} to="/branding">
              brand resources page
            </Link>
            {' '}
            in a manner that&apos;s obvious to the casual user that the voice biometrics services are provided by VoiceIt Technologies, Inc.

          </ListItem>
        </List>

        <Typography variant="h6" sx={{ my: 2 }}>Termination</Typography>
        <Typography sx={{ my: 0.5 }}>
          We may terminate or suspend Your access immediately, without prior notice or liability,
          for any reason whatsoever, including without limitation if You breach these Terms and Conditions.

        </Typography>
        <Typography sx={{ my: 0.5 }}>Upon termination, Your right to use the Service will cease immediately.</Typography>

        <Typography variant="h6" sx={{ my: 2 }}>Limitation of Liability</Typography>
        <Typography sx={{ my: 0.5 }}>
          Notwithstanding any damages that You might incur,
          the entire liability of the Company and any of its suppliers under any provision of this
          Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount
          actually paid by You through the Service or 100 USD if You haven&apos;t purchased anything through the Service.

        </Typography>
        <Typography sx={{ my: 0.5 }}>
          To the maximum extent permitted by applicable law,
          in no event shall the Company or its suppliers be liable for any special, incidental,
          indirect, or consequential damages whatsoever (including, but not limited to,
          damages for loss of profits, loss of data or other information, for business interruption,
          for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service,
          third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms),
          even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.

        </Typography>
        <Typography sx={{ my: 0.5 }}>
          Some states do not allow the exclusion of implied warranties or limitation of liability
          for incidental or consequential damages, which means that some of the above limitations may not apply. In these states,
          each party&apos;s liability will be limited to the greatest extent permitted by law.

        </Typography>

        <Typography variant="h6" sx={{ my: 2 }}>&quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer</Typography>
        <Typography sx={{ my: 0.5 }}>
          The Service is provided to You &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and with
          all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law,
          the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and
          service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise,
          with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose,
          title and non-infringement, and warranties that may arise out of course of dealing, course of performance,
          usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking,
          and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results,
          be compatible or work with any other software, applications, systems or services, operate without interruption, meet
          any performance or reliability standards or be error free or that any errors or defects can or will be corrected.

        </Typography>
        <Typography sx={{ my: 0.5 }}>
          Without limiting the foregoing, neither the Company nor any of the company&apos;s provider makes any
          representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service,
          or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted
          or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service;
          or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses,
          scripts, trojan horses, worms, malware, timebombs or other harmful components.

        </Typography>
        <Typography sx={{ my: 0.5 }}>
          Some jurisdictions do not allow the exclusion of certain types of warranties or
          limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and
          limitations may not apply to You. But in such a case the exclusions and limitations set forth in this
          section shall be applied to the greatest extent enforceable under applicable law.

        </Typography>

        <Typography variant="h6" sx={{ my: 2 }}>Governing Law</Typography>
        <Typography sx={{ my: 0.5 }}>
          The laws of the Country, excluding its conflicts of law rules,
          shall govern this Terms and Your use of the Service.
          Your use of the Application may also be subject to other local, state, national, or international laws.
        </Typography>

        <Typography variant="h6" sx={{ my: 2 }}>Disputes Resolution</Typography>
        <Typography sx={{ my: 0.5 }}>
          If You have any concern or dispute about the Service,
          You agree to first try to resolve the dispute informally by contacting the Company.

        </Typography>

        <Typography variant="h6" sx={{ my: 2 }}>For European Union (EU) Users</Typography>
        <Typography sx={{ my: 0.5 }}>
          If You are a European Union consumer, you will benefit from
          any mandatory provisions of the law of the country in which you are resident in.
        </Typography>

        <Typography variant="h6" sx={{ my: 2 }}>United States Legal Compliance</Typography>
        <Typography sx={{ my: 0.5 }}>
          You represent and warrant that (i) You are not located in a country that
          is subject to the United States government embargo, or that has been designated by the United States
          government as a &quot;terrorist supporting&quot; country, and (ii) You are not listed on any United States
          government list of prohibited or restricted parties.
        </Typography>

        <Typography variant="h6" sx={{ my: 2 }}>Severability and Waiver</Typography>
        <Typography variant="h3" sx={{ my: 2 }}>Severability</Typography>
        <Typography sx={{ my: 0.5 }}>
          If any provision of these Terms is held to be unenforceable or invalid,
          such provision will be changed and interpreted to accomplish the objectives of such provision
          to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
        </Typography>
        <Typography variant="h3" sx={{ my: 2 }}>Waiver</Typography>
        <Typography sx={{ my: 0.5 }}>
          Except as provided herein,
          the failure to exercise a right or to require performance of an obligation under these Terms shall
          not effect a party&apos;s ability to exercise such right or require such performance at any time thereafter
          nor shall the waiver of a breach constitute a waiver of any subsequent breach.
        </Typography>

        <Typography variant="h6" sx={{ my: 2 }}>Translation Interpretation</Typography>
        <Typography sx={{ my: 0.5 }}>
          These Terms and Conditions may have been translated if We have made them available to You on our Service.
          You agree that the original English text shall prevail in the case of a dispute.
        </Typography>

        <Typography variant="h6" sx={{ my: 2 }}>Changes to These Terms and Conditions</Typography>
        <Typography sx={{ my: 0.5 }}>
          We reserve the right, at Our sole discretion, to modify or replace these Terms at any time.
          If a revision is material We will make reasonable efforts to provide at least 30 days&apos; notice prior to any new terms taking effect.
          What constitutes a material change will be determined at Our sole discretion.
        </Typography>
        <Typography sx={{ my: 0.5 }}>
          By continuing to access or use Our Service after those revisions become effective,
          You agree to be bound by the revised terms.
          If You do not agree to the new terms, in whole or in part,
          please stop using the website and the Service.
        </Typography>
      </Container>
    </Box>
  );
}
