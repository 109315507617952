/* eslint-disable react/prop-types */
import React from 'react';
import {
  Stack, Link, Box, Typography, Paper,
} from '@mui/material';
import locationIcon from '@iconify/icons-carbon/location';
import mobileIcon from '@iconify/icons-carbon/mobile';
import emailIcon from '@iconify/icons-carbon/email';
import { m } from 'framer-motion';
import Iconify from '../Iconify';
import ContactMap from '../Map/ContactMap';

function TextIconLabel({
  icon, value, endIcon = false, sx, ...other
}) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        ...sx,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {!endIcon && icon}
      {value}
      {endIcon && icon}
    </Stack>
  );
}

export default function ContactMapInfo() {
  return (
    <Paper
      component={m.div}
      whileHover="hover"
      sx={{
        backgroundColor: '#f2f5f4',
        borderRadius: 2,
        overflow: 'hidden',
        '&:hover': {
          boxShadow: (theme) => theme.customShadows.z24,
        },
      }}
    >
      <Stack spacing={1.5} sx={{ p: 3 }} component={m.div} variants={{ hover: { opacity: 0.8 } }}>
        <Typography variant="h3" sx={{ color: '#000000', mb: 0.5 }}>
          Main Office
        </Typography>
        <Stack spacing={0.5}>
          <TextIconLabel
            icon={<Iconify icon={locationIcon} sx={{ mr: 1, width: 24, height: 24 }} />}
            value={(
              <>
                Address:
              </>
            )}
            sx={{ typography: 'subtitle2', mb: 0.5 }}
          />
          <Typography variant="body2">222 South 9th Street</Typography>
          <Typography variant="body2">Suite 1600</Typography>
          <Typography variant="body2">Minneapolis, MN 55402</Typography>
        </Stack>

        <Stack spacing={0.5}>
          <TextIconLabel
            icon={<Iconify icon={mobileIcon} sx={{ mr: 1, width: 24, height: 24 }} />}
            value="Phone:"
            sx={{ typography: 'subtitle2', mb: 0.5 }}
          />
          <Typography variant="body2">
            <Link
              sx={{ lineHeight: 0 }}
              rel="noopener noreferrer"
              href="tel:612-423-9015"
            >
              612-423-9015
            </Link>
          </Typography>
        </Stack>

        <Stack spacing={0.5}>
          <TextIconLabel
            icon={<Iconify icon={emailIcon} sx={{ mr: 1, width: 24, height: 24 }} />}
            value="Email:"
            sx={{ typography: 'subtitle2', mb: 0.5 }}
          />
          <Typography variant="body2">
            <Link
              sx={{ lineHeight: 0 }}
              rel="noopener noreferrer"
              href="mailto:sales@voiceit.io"
            >
              sales@voiceit.io
            </Link>
          </Typography>
        </Stack>
      </Stack>
      <Box sx={{ overflow: 'hidden' }}>
        <m.div>
          <ContactMap sx={{ borderRadius: 2 }} />
        </m.div>
      </Box>

    </Paper>

  );
}
